import { directusApi } from '../service/directusApi';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import loadingBarReducer from '../reducer/loadingBarSlice';

export const store = configureStore({
  reducer: {
    [directusApi.reducerPath]: directusApi.reducer,
    loadingBar: loadingBarReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(directusApi.middleware)
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);