import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { Config } from '../config';

export type GameDeveloper = {
  id: number,
  developer: string
};
export type GamePlatform = 'pc' | 'switch' | 'xbox' | 'playstation';
export type GamePublisher = {
  id: number,
  publisher: string
};
export type GameRating = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
export type PostStatus = 'archived' | 'draft' | 'published';

export type GameData = {
  cover_image: string,
  developer: GameDeveloper,
  icon: string,
  id: number,
  name: string,
  platforms_available: Array<GamePlatform>,
  publisher: GamePublisher,
  release_year: number
};

export type ReviewData = {
  content: string | null,
  date_created: string,
  date_updated: string | null,
  game: GameData,
  id: number,
  platforms_played: Array<GamePlatform>,
  rating: GameRating,
  slug: string,
  status: PostStatus
  title: string
};

export type PageData = {
  content: string,
  slug: string,
  status: PostStatus,
  title: string
};

type PageResponse = {
  data: Array<PageData>
};

type ReviewResponse = {
  data: Array<ReviewData>,
  meta?: {
    filter_count: number
  }
}

export const directusApi = createApi({
  reducerPath: 'postsApi',
  baseQuery: fetchBaseQuery({ baseUrl: Config.API_URL }),
  endpoints: (builder) => ({
    getPageBySlug: builder.query<PageData | null, string>({
      query: (slug) => `Page?fields[]=*.*&filter[slug][_eq]=${slug}`,
      transformResponse: (response: PageResponse) => {
        if (response.data.length > 0) {
          return response.data[0];
        }
        return null;
      }
    }),
    getPosts: builder.query<Array<ReviewData>, void>({
      query: () => 'Review?fields[]=*.*&fields[]=game.developer.*&fields[]=game.publisher.*&limit=6&sort=-date_created',
      transformResponse: (response: ReviewResponse) => {
        return response.data;
      }
    }),
    getPostsAlphabetically: builder.query<{ posts: Array<ReviewData>, pageCount: number }, number>({
      query: (page?: number) =>
          `Review?fields[]=*.*&sort=title&meta=filter_count&limit=${Config.REVIEW_PAGE_SIZE}&offset=${page ? (page - 1) * Config.REVIEW_PAGE_SIZE : 0}`,
      transformResponse: (response: ReviewResponse) => {
        return {
          posts: response.data,
          pageCount: Math.ceil((response.meta?.filter_count || 1) / Config.REVIEW_PAGE_SIZE)
        };
      }
    }),
    getReviewBySlug: builder.query<ReviewData | null, string>({
      query: (slug) => `Review?fields[]=*.*&fields[]=game.developer.*&fields[]=game.publisher.*&filter[slug][_eq]=${slug}`,
      transformResponse: (response: ReviewResponse) => {
        if (response.data.length > 0) {
          return response.data[0];
        }
        return null;
      }
    }),
  })
});

export const {
  useGetPageBySlugQuery,
  useGetPostsQuery,
  useGetPostsAlphabeticallyQuery,
  useGetReviewBySlugQuery
} = directusApi;