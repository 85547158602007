import { GamePlatform } from '../service/directusApi';
import { Stack } from '@mui/material';
import { ReactComponent as PCIcon } from '../icons/ic_windows.svg';
import { ReactComponent as SwitchIcon } from '../icons/ic_switch.svg';
import { ReactComponent as XboxIcon } from '../icons/ic_xbox.svg';
import { ReactComponent as PlayStationIcon } from '../icons/ic_playstation.svg';

type PlatformIconsProps = {
  available: Array<GamePlatform>,
  played: Array<GamePlatform>
};

const PlatformIcons = ({ available, played }: PlatformIconsProps) => {
  const availableMap = {
    pc: available.includes('pc'),
    switch: available.includes('switch'),
    xbox: available.includes('xbox'),
    playstation: available.includes('playstation')
  };
  const playedMap = {
    pc: played.includes('pc'),
    switch: played.includes('switch'),
    xbox: played.includes('xbox'),
    playstation: played.includes('playstation')
  };
  const INACTIVE_OPACITY = 0.35;

  return (
    <Stack direction="row" spacing={1} height={24} sx={{ display: 'inline-flex' }}>
      <PCIcon width={24} height={24} style={{
        opacity: playedMap['pc'] ? 1 : INACTIVE_OPACITY,
        filter: availableMap['pc'] ? 'none' : 'grayscale(1)'
      }} />
      <SwitchIcon width={24} height={24} style={{
        opacity: playedMap['switch'] ? 1 : INACTIVE_OPACITY,
        filter: availableMap['switch'] ? 'none' : 'grayscale(1)'
      }}/>
      <XboxIcon width={24} height={24} style={{
        opacity: playedMap['xbox'] ? 1 : INACTIVE_OPACITY,
        filter: availableMap['xbox'] ? 'none' : 'grayscale(1)'
      }} />
      <PlayStationIcon width={24} height={24} style={{
        opacity: playedMap['playstation'] ? 1 : INACTIVE_OPACITY,
        filter: availableMap['playstation'] ? 'none' : 'grayscale(1)'
      }} />
    </Stack>
  );
};

export default PlatformIcons;