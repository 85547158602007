import {
  Box,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import {Config} from '../config';
import { ReviewData } from '../service/directusApi';
import PlatformIcons from './PlatformIcons';
import Rating from './Rating';
import StatsTable from './StatsTable';

type PostProps = {
  post: ReviewData
};

const Post = ({ post }: PostProps) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  console.log(post);

  return (
    <Box>
      {post && (
        <>
          <Box
            sx={{
              position: 'relative',
              width: { xs: 'calc(100% + 32px)', sm: '100%' },
              mt: { xs: -2, sm: 0 },
              mb: 2,
              ml: { xs: -2, sm: 0 },
              pb: '56.25%',
              backgroundImage: `url(${Config.ASSETS_URL}${post.game.cover_image})`,
              backgroundSize: 'cover'
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: 60,
                px: 1,
                backgroundColor: 'rgba(0, 0, 0, 0.75)'
              }}
            >
              <Box
                sx={{
                  width: 48,
                  height: 48,
                  mr: 2,
                  backgroundImage: `url(${Config.ASSETS_URL}${post.game.icon})`,
                  backgroundSize: 'cover'
                }}
              />
              <Typography
                variant="h3"
                color="white"
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  mb: 0,
                  flex: 1
                }}
              >
                {post.game.name}
                {isMdUp && <Rating rating={post.rating}/>}
              </Typography>
              {isMdUp && <PlatformIcons available={post.game.platforms_available} played={post.platforms_played}/>}
            </Box>
          </Box>
          <StatsTable
            developer={post.game.developer.developer}
            platformsAvailable={post.game.platforms_available}
            platformsPlayed={post.platforms_played}
            publisher={post.game.publisher.publisher}
            rating={post.rating}
            releaseYear={post.game.release_year}
          />
          <Box dangerouslySetInnerHTML={{ __html: post.content || '' }} sx={{ py: 2 }} />
        </>
      )}
    </Box>
  );
};

export default Post;
